import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderOverige from '../components/HeaderOverige'
import OverigeNav from '../components/NavOverige'
import { Waypoint } from 'react-waypoint'

class Overige extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {

    return (
      <Layout>
        <Helmet title="Smakenlijst" />

        <Waypoint
          onEnter={this.handleWaypointEnter}
          onLeave={this.handleWaypointLeave}
        >
        </Waypoint>

        <HeaderOverige />

        <OverigeNav sticky={this.state.sticky} />

        <div id="main">
          <section id="content" className="main">

            <h2 id="smakenlijst">Smakenlijst</h2>
            <h3>Cake smaken</h3>
            <ul>
              <li>Vanille</li>
              <li>Chocolade</li>
              <li>Red velvet</li>
            </ul>

            <h3>Vulling smaken</h3>
            <ul>
              <li>Vanille</li>
              <li>Chocolaade</li>
              <li>Witte chocolade</li>
              <li>Oreo</li>
              <li>Aardbei</li>
              <li>Cream cheese</li>
              <li>Passievrucht</li>
              <li>Slagroom</li>
              <li>Mokka</li>
              <li>Caramel</li>
              <li>Cocos</li>
              <li>Raffaelo</li>
            </ul>

            <p>
              <strong>
                Heeft u zelf een smaak wat niet op de lijst staat en wat u toch nog graag zou willen, kunt u het altijd laten weten en proberen wij het te regelen.
              </strong>
            </p>

            <h3 id="hartige-snacks">Hartige antiliaanse snacks</h3>
            <ul>
              <li>Pasteitjes gehakt of kaas <span className="float-right">€1,00</span></li>
              <li>Kaasballen <span className="float-right">€0,80</span></li>
              <li>Worstjes in bladerdeeg <span className="float-right">€1,00</span></li>
            </ul>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Overige
