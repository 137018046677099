import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const OverigeNav = (props) => (
  <nav id="nav" className={props.sticky ? 'alt' : ''}>
    <Scrollspy items={['smakenlijst', 'hartige-snacks']} currentClassName="is-active" offset={-300}>
      <li>
        <Scroll type="id" element="smakenlijst">
          <a href="#">Smakenlijst</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="hartige-snacks">
          <a href="#">Hartige Snacks</a>
        </Scroll>
      </li>
      <li>
        <a href="/">Home</a>
      </li>
    </Scrollspy>
  </nav>
)

export default OverigeNav
