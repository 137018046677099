import React from 'react'

const HeaderOverige = (props) => (
    <header id="header">
        <h1>Overige</h1>
        <p>Smaken, Snacks en meer</p>
    </header>
)

export default HeaderOverige
